.image-container {
    position: relative;
    width: 2000px;
    height: 1955px;
  }
  
  .image {
    width: 100%;
    height: 100%;
    display: block;
  }
  
  .button {
    position: absolute;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  /* Button positions */
  .button-1 {
    top: 1301px;
    left: 80px;
    width:54px;
    font-size: 11px;
    padding: 2px;
  }
  
  .button-2 {
    top: 1790px;
    left: 1716px;
    width:54px;
    font-size: 11px;
    padding: 2px;
  }
  
  .button-3 {
    bottom: 310px;
    left: 50%;
  }

  .button-4 {
    bottom: 347px;
    left: 50%;
  }